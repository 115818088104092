.DoctorsManager-Content
  display: grid
  grid-template-columns: 1fr
  grid-gap: 48px
  padding: 32px 0px
  .Field-Textarea
    grid-column: 1/-1
  .Title
    font-size: 18px
    font-weight: 500
  .MainInfo
    display: grid
    grid-template-columns: 1fr
    grid-gap: 64px
  .Char
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px
    align-items: start
    .Fieldset
      display: grid
      grid-template-columns: 1fr max-content
      grid-gap: 16px
      .Fields
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 16px

  .Buttons
    display: flex
    .Button
      &:not(:last-child)
        margin-right: 16px
